import { onAuthStateChanged, signOut } from 'firebase/auth'
import { onValue, ref } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { RiCloseFill, RiCheckDoubleLine } from 'react-icons/ri'
import {
  setDbHeadings,
  updateDate,
  uploadFirstDocsAndDownload,
} from '../../config/firebase'
import { dbRef, auth } from '../../config/firebaseConfig'
import { useNavigate } from 'react-router-dom'

const inputValues = {
  heading: '',
  operated: '',
  email: '',
  telephone: '',
  fax: '',
  address: '',
  docOne: '',
  details:''
}

const fieldsBool = {
  heading: false,
  operated: false,
  email: false,
  telephone: false,
  fax: false,
  address: false,
  docOneUrl: false,
  docTwoUrl: false,
  docThreeUrl: false,
  docFourUrl : false,
  dateOne: false,
  dateTwo: false,
  dateThree: false,
  details:false
}

const Dashboard = () => {
  const nevigate = useNavigate()

  // input fields show/hide state
  const [fieldsShow, setFieldsShow] = useState(fieldsBool)
  //   loading state
  const [loading, setLoading] = useState(fieldsBool)

  //   set input show
  const handleInputShow = (e) => {
    setFieldsShow({ ...fieldsShow, [e]: true })
  }

  //   hide input
  const handleHide = (e) => {
    setFieldsShow({ ...fieldsShow, [e]: false })
  }

  //   inputs value state
  const [fieldsVal, setFieldsVal] = useState(inputValues)
  //   set input value function
  const handleInputChange = (e) => {
    setFieldsVal({ ...fieldsVal, [e.target.name]: e.target.value })
  }
  //   added data in firebase
  const handleAddData = async (e) => {
    setLoading({ ...loading, [e]: true })
    // console.log('clicked')
    setDbHeadings(fieldsVal)
      .then((res) => {
        setLoading({ ...loading, [e]: false })
        setFieldsShow({ ...fieldsShow, [e]: false })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    const readData = async () => {
      // checking user login or not
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid
          // console.log(user);
          // ...
        } else {
          console.log('logout')
          nevigate('/login')
          // User is signed out
          // ...
        }
      })
      // getting data from firebase
      onValue(dbRef('heading'), (snapshot) => {
        const data = snapshot.val()
        setFieldsVal({
          ...fieldsVal,
          heading: data.heading,
          operated: data.operated,
          email: data.email,
          telephone: data.telephone,
          fax: data.fax,
          address: data.address,
          details:data.details
        })
      })
      onValue(dbRef('docsOne'), (snapshot) => {
        const data = snapshot.val()
        setFileOne({ doc: data.docUrl, date: data.date })
      })
      onValue(dbRef('docsTwo'), (snapshot) => {
        const data = snapshot.val()
        setFileTwo({ doc: data.docUrl, date: data.date })
      })
      onValue(dbRef('docsThree'), (snapshot) => {
        const data = snapshot.val()
        setFileThre({doc:data.docUrl, date: data.date})
      })
      onValue(dbRef('docsFour'), (snapshot) => {
        const data = snapshot.val()
        setFileFour({doc:data.docUrl, date: data.date})
      })
    }
    readData()
  }, [])

  const [documents, setDocuments] = useState({
    docOneUrl: '',
    docTwoUrl: '',
    docThreeUrl: '',
    docFourUrl : '',
  })

  const [dates, setDates] = useState({ dateOne: '', dateTwo: '' , dateThree:''})

  const [fileOne, setFileOne] = useState({ doc: '', date: '' })
  const [fileTwo, setFileTwo] = useState({ doc: '', date: '' })
  const [fileThre, setFileThre] = useState({ doc: '', date: '' })
  const [fileFour, setFileFour] = useState({ doc: '', date: '' })

  const handleDocumentChange = (e) => {
    setDocuments({ ...documents, [e.target.id]: e.target.files[0] })
  }

  const handleDateChange = (e) => {
    setDates({ ...dates, [e.target.id]: e.target.value })
  }

  const handleAddFirstDocument = async () => {
    setLoading({ ...loading, docOneUrl: true })
    uploadFirstDocsAndDownload(documents.docOneUrl, 'docsOne',fileOne.date)
    setLoading({ ...loading, docOneUrl: false })
    setFieldsShow({ ...fieldsShow, docOneUrl: false })
  }

  const handleAddSecondDoc = async () => {
    setLoading({ ...loading, docTwoUrl: true })
    await uploadFirstDocsAndDownload(documents.docTwoUrl, 'docsTwo',fileTwo.date)
    setLoading({ ...loading, docTwoUrl: false })
    setFieldsShow({ ...fieldsShow, docTwoUrl: false })
  }
  const handleAddThirdDoc = async () => {
    setLoading({ ...loading, docThreeUrl: true })
    await uploadFirstDocsAndDownload(documents.docThreeUrl, 'docsThree' , fileThre.date)
    setLoading({ ...loading, docThreeUrl: false })
    setFieldsShow({ ...fieldsShow, docThreeUrl: false })
  }
  const handleAddForthDoc = async () => {
    setLoading({ ...loading, docFourUrl: true })
    await uploadFirstDocsAndDownload(documents.docFourUrl, 'docsFour' , fileFour.date)
    setLoading({ ...loading, docFourUrl: false })
    setFieldsShow({ ...fieldsShow, docFourUrl: false })
  }

  const handleUpdateDate = async (e) => {
    if (e === 'dateOne') {
      setLoading({ ...loading, dateOne: true })
      updateDate(fileOne.doc, 'docsOne', dates.dateOne)
      setLoading({ ...loading, dateOne: false })
      setFieldsShow({ ...fieldsShow, dateOne: false })
    } else if (e === 'dateTwo') {
      setLoading({ ...loading, dateTwo: true })
      console.log('datetwo');
      console.log(fileTwo)
      updateDate(fileTwo.doc, 'docsTwo', dates.dateTwo)
      setLoading({ ...loading, dateTwo: false })
      setFieldsShow({ ...fieldsShow, dateTwo: false })
    }else if(e==='dateThree'){
      setLoading({ ...loading, dateThree: true })
      updateDate(fileThre.doc, 'docsThree', dates.dateThree)
      setLoading({ ...loading, dateThree: false })
      setFieldsShow({ ...fieldsShow, dateThree: false })
    }
    // else if(e==='dateFour'){
    //   setLoading({ ...loading, dateFour: true })
    //   updateDate(fileFour.doc, 'docsFour', dates.dateFour)
    //   setLoading({ ...loading, dateFour: false })
    //   setFieldsShow({ ...fieldsShow, dateFour: false })
    // }
  }

  const handleLogout = async () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        nevigate('/login')
      })
      .catch((error) => {
        // An error happened.
      })
  }

  return (
    <div className="cardContainer">
      <div>
        <button className="blueBtn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="adHead">
        <h1>Dashboard</h1>
      </div>
      <div className="dbContent">
        <div className="fields">
          <h3>Heading Update</h3>
          <div className="fieldUp">
            {!fieldsShow.heading ? (
              <div className="dbCOnt">
                <p>{fieldsVal.heading ? fieldsVal.heading : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('heading')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  name="heading"
                  onChange={handleInputChange}
                  value={fieldsVal.heading}
                  id="heading"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('heading')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    disabled={loading.heading}
                    onClick={() => handleAddData('heading')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pageName">
          <h2>Imprint Page</h2>
        </div>
        <div className="fields">
          <h3>Operated Update</h3>
          <div className="fieldUp">
            {!fieldsShow.operated ? (
              <div className="dbCOnt">
                <p>{fieldsVal.operated ? fieldsVal.operated : 'loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('operated')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={fieldsVal.operated}
                  name="operated"
                  id="operated"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('operated')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('operated')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Address Update</h3>
          <div className="fieldUp">
            {!fieldsShow.address ? (
              <div className="dbCOnt">
                <textarea
                  readOnly
                  cols="30"
                  value={fieldsVal.address}
                ></textarea>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('address')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <textarea
                  name="address"
                  id="address"
                  onChange={handleInputChange}
                  value={fieldsVal.address}
                  cols="30"
                  rows="6"
                ></textarea>
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('address')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('address')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Email Update</h3>
          <div className="fieldUp">
            {!fieldsShow.email ? (
              <div className="dbCOnt">
                <p>{fieldsVal.email ? fieldsVal.email : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('email')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="email"
                  onChange={handleInputChange}
                  value={fieldsVal.email}
                  name="email"
                  id="email"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('email')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('email')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Telephone Update</h3>
          <div className="fieldUp">
            {!fieldsShow.telephone ? (
              <div className="dbCOnt">
                <p>
                  {fieldsVal.telephone ? fieldsVal.telephone : 'Loading...'}
                </p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('telephone')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={fieldsVal.telephone}
                  name="telephone"
                  id="telephone"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('telephone')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('telephone')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Fax Update</h3>
          <div className="fieldUp">
            {!fieldsShow.fax ? (
              <div className="dbCOnt">
                <p>{fieldsVal.fax ? fieldsVal.fax : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('fax')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={fieldsVal.fax}
                  name="fax"
                  id="fax"
                />
                <div className="iconsBtns">
                  <button className="cnclBtn" onClick={() => handleHide('fax')}>
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('fax')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Imprint Detail Update</h3>
          <div className="fieldUp">
            {!fieldsShow.details ? (
              <div className="dbCOnt">
                <p>{fieldsVal.details ? fieldsVal.details : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('details')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={fieldsVal.details}
                  name="details"
                  id="details"
                />
                <div className="iconsBtns">
                  <button className="cnclBtn" onClick={() => handleHide('details')}>
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddData('details')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pageName">
          <h2>Document Page</h2>
        </div>
        <div className="fields">
          <h3>Date One</h3>
          <div className="fieldUp">
            {!fieldsShow.dateOne ? (
              <div className="dbCOnt">
                <p>{fileOne.date ? fileOne.date : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('dateOne')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleDateChange}
                  value={dates.dateOne}
                  name="dateOne"
                  id="dateOne"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('dateOne')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleUpdateDate('dateOne')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Date Two</h3>
          <div className="fieldUp">
            {console.log(fieldsShow)}
            {!fieldsShow.dateTwo ? (
              <div className="dbCOnt">
                <p>{fileTwo.date ? fileTwo.date : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('dateTwo')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleDateChange}
                  value={dates.dateTwo}
                  name="dateTwo"
                  id="dateTwo"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('dateTwo')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleUpdateDate('dateTwo')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ---------new */}
        <div className="fields">
          <h3>Date Three</h3>
          <div className="fieldUp">
            {!fieldsShow.dateThree ? (
              <div className="dbCOnt">
                <p>{fileThre.date ? fileThre.date : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('dateThree')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleDateChange}
                  value={dates.dateThree}
                  name="dateThree"
                  id="dateThree"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('dateThree')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleUpdateDate('dateThree')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ---------new */}
        {/** 
        <div className="fields">
          <h3>Date Four</h3>
          <div className="fieldUp">
            {!fieldsShow.dateFour ? (
              <div className="dbCOnt">
                <p>{fileFour.date ? fileFour.date : 'Loading...'}</p>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('dateFour')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="text"
                  onChange={handleDateChange}
                  value={dates.dateFour}
                  name="dateFour"
                  id="dateFour"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('dateFour')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleUpdateDate('dateFour')}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
            */}

        {/* date end */}
        <div className="fields">
          <h3>Document 1</h3>
          <div className="fieldUp">
            {!fieldsShow.docOneUrl ? (
              <div className="dbCOnt">
                <iframe
                  // width="70%"
                  height="150px"
                  src={fileOne.doc}
                  frameBorder="0"
                ></iframe>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('docOneUrl')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="file"
                  onChange={handleDocumentChange}
                  // value={fieldsVal.fax}
                  name="docOneUrl"
                  id="docOneUrl"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('docOneUrl')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddFirstDocument()}
                    disabled={loading.docOneUrl}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fields">
          <h3>Document Two</h3>
          <div className="fieldUp">
            {!fieldsShow.docTwoUrl ? (
              <div className="dbCOnt">
                <iframe
                  // width="70%"
                  height="150px"
                  src={fileTwo.doc}
                  frameBorder="0"
                ></iframe>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('docTwoUrl')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="file"
                  onChange={handleDocumentChange}
                  // value={fieldsVal.fax}
                  name="docTwoUrl"
                  id="docTwoUrl"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('docTwoUrl')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddSecondDoc()}
                    disabled={loading.docTwoUrl}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ---------- */}
       
        <div className="fields">
          <h3>Document Three</h3>
          <div className="fieldUp">
            {!fieldsShow.docThreeUrl ? (
              <div className="dbCOnt">
                <iframe
                  // width="70%"
                  height="150px"
                  src={fileThre.doc}
                  frameBorder="0"
                ></iframe>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('docThreeUrl')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="file"
                  onChange={handleDocumentChange}
                  // value={fieldsVal.fax}
                  name="docThreeUrl"
                  id="docThreeUrl"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('docThreeUrl')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddThirdDoc()}
                    disabled={loading.docThreeUrl}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ---------- */}
       
        <div className="fields">
          <h3>Document Four</h3>
          <div className="fieldUp">
            {!fieldsShow.docFourUrl ? (
              <div className="dbCOnt">
                <iframe
                  // width="70%"
                  height="150px"
                  src={fileFour.doc}
                  frameBorder="0"
                ></iframe>
                <button
                  className="iconBtn"
                  onClick={() => handleInputShow('docFourUrl')}
                >
                  <FiEdit />
                </button>
              </div>
            ) : (
              <div className="inpts">
                <input
                  type="file"
                  onChange={handleDocumentChange}
                  // value={fieldsVal.fax}
                  name="docFourUrl"
                  id="docFourUrl"
                />
                <div className="iconsBtns">
                  <button
                    className="cnclBtn"
                    onClick={() => handleHide('docFourUrl')}
                  >
                    <RiCloseFill />
                  </button>
                  <button
                    className="checkBtn"
                    onClick={() => handleAddForthDoc()}
                    disabled={loading.docFourUrl}
                  >
                    <RiCheckDoubleLine />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
