import { onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { dbRef } from "../../config/firebaseConfig";
import { Link, useNavigate } from "react-router-dom";

const Documents = () => {
  const [docsDetailOne, setDocsDetailOne] = useState({
    url: "",
    date: "",
  });
  const [docsDetailTwo, setDocsDetailTwo] = useState({
    url: "",
    date: "",
  });
  const [docsDetailThree, setDocsDetailThree] = useState({
    url: "",
    date: "",
  });
  const [docsDetailFour, setDocsDetailFour] = useState({
    url: "",
    // date: "",
  });
  const navigate = useNavigate;

  useEffect(() => {
    const getDbData = async () => {
      onValue(dbRef("docsOne"), (snapshot) => {
        const data = snapshot.val();
        // setFileOne(data.docUrl)
        setDocsDetailOne({ url: data.docUrl, date: data.date });
      });
      onValue(dbRef("docsTwo"), (snapshot) => {
        const data = snapshot.val();
        // setFileTwo(data.docUrl)
        setDocsDetailTwo({ url: data.docUrl, date: data.date });
      });
      onValue(dbRef("docsThree"), (snapshot) => {
        const data = snapshot.val();
        setDocsDetailThree({ url: data.docUrl, date: data.date });
        // setFileThre(data.docUrl)
      });
      onValue(dbRef("docsFour"), (snapshot) => {
        const data = snapshot.val();
        console.log("==========================")
        console.log(data?.docUrl)
        setDocsDetailFour({ url: data.docUrl, date: data.date });
        // setFileThre(data.docUrl)
      });
    };
    getDbData();
  }, []);

  console.log("nbvhvb");
  console.log(docsDetailFour)

  return (
    <div className="boxContent">
      <div className="termsHead">
        <h2>Dokumente</h2>
      </div>
      <table className="docTable font_calibr">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Beschreibung</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* {new Date(
                docsDetailOne.date ? docsDetailOne.date : null,
              ).toDateString()} */}
              {docsDetailOne.date}
            </td>
            <td>
              <Link
                className="desk"
                to="/download"
                state={{ link: docsDetailOne.url }}
              >
                Angebotsunterlage
              </Link>

              <a
                className="mb"
                href={docsDetailOne.url}
                download="Angebotsunterlage.pdf"
                target="_blank"
              >
                Angebotsunterlage
              </a>
            </td>
          </tr>
          <tr>
            <td>
              {/* {new Date(
                docsDetailTwo.date ? docsDetailTwo.date : null,
              ).toDateString()} */}
              {docsDetailTwo.date}
            </td>
            <td>
              <Link
                className="desk"
                to="/download"
                state={{ link: docsDetailTwo.url }}
              >
                Schreiben an alle Anteilsinhaberinnen und Anteilsinhaber
              </Link>

              <a
                className="mb"
                href={docsDetailTwo.url}
                download="Schreiben.pdf"
                target="_blank"
              >
                Schreiben an alle Anteilsinhaberinnen und Anteilsinhaber
              </a>
            </td>
          </tr>
          {/* ----new */}
          <tr>
            <td>
              {/* {new Date(
                docsDetailTwo.date ? docsDetailTwo.date : null,
              ).toDateString()} */}
              {docsDetailThree.date}
              {console.log(docsDetailThree)}
            </td>
            <td>
              <Link
                className="desk"
                to="/download"
                state={{ link: docsDetailThree.url }}
              >
                Häufig gestellte Fragen
              </Link>

              <a
                className="mb"
                href={docsDetailThree.url}
                download="Schreiben.pdf"
                target="_blank"
              >
                Häufig gestellte Fragen
              </a>
            </td>
          </tr>
          {/* forth */}
        
        
        </tbody>
      </table>
      <div className="docContent">
        <div className="docBox">
          <h5>
            Zur Annahme des Angebots wenden Sie sich bitte an Ihre Depotbank.
            Alternativ ist unten das Formular einer Annahmeerklärung beigefügt,
            das Sie ausfüllen und an Ihre Depotbank schicken können.
          </h5>
        </div>
        <Link
          className="desk"
          to="/download"
          state={{ link: docsDetailFour.url }}
        >
          Formular Annahmeerklärung
        </Link>

        <a
          className="mb"
          href={docsDetailFour.url}
          download="Formular-Annahmeerklärung.pdf"
          target="_blank"
        >
          Formular Annahmeerklärung
        </a>
      </div>
    </div>
  );
};

export default Documents;
