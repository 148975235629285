import './App.css'
import AppRouter from './Router/Router'

function App() {
  return (
    <div className="App">
      
      <AppRouter />
      {/* <div className='sm_ftr'>
        <p>
        Copyright © {new Date().getFullYear()}, All Right Reserved{' '}
          <a href="https://smashcode.dev/" rel="" target="_blank">
            Smash Code
          </a>{' '}
        </p>
      </div> */}
    </div>
  )
}

export default App
