import React from 'react'
import CardBox from '../../Component/cardBox'
import ImPrint from './ImPrint'

const ImPrintPage = () => {
  return (
    <div>
      <CardBox children={<ImPrint />} />
    </div>
  )
}

export default ImPrintPage
