import React, { useState } from 'react'
import LoginForm from './loginForm'
import {  useNavigate } from 'react-router-dom'
import { auth } from '../../config/firebaseConfig'
import { signInWithEmailAndPassword } from 'firebase/auth'
import './login.css'

const inputFieldValue = {
  email: '',
  password: '',
}

const Login = () => {
  const nevigate = useNavigate()
  // let auth = auth.auth();
  const [loginInputs, setLoginInputs] = useState(inputFieldValue)
  const handleChangeLoginInputs = (event) => {
    setLoginInputs({
      ...loginInputs,
      [event.target.name]: event.target.value,
    })
  }

  const handleLoginSubmit = (event) => {
    event.preventDefault()
    let { email, password } = loginInputs
    // auth
    signInWithEmailAndPassword(auth, email, password)
      .then((userLogin) => {
        nevigate('/admin-panelSari')
        setLoginInputs(inputFieldValue)
      })
      .catch((error) => {})
  }

  return (
    <div>
      <LoginForm
        handleChange={handleChangeLoginInputs}
        handleSubmit={handleLoginSubmit}
        inputsValues={loginInputs}
        // loader={loader}
      />
    </div>
  )
}

export default Login
