import React, { useState,useEffect } from 'react'
import {  NavLink } from 'react-router-dom'
import { onValue } from 'firebase/database'
import { dbRef } from '../config/firebaseConfig'

const CardBox = ({ children, button }) => {
  const [heading, setHeading] = useState(null)

  useEffect(() => {
    const readData = async () => {
      onValue(dbRef('heading'), (snapshot) => {
        const data = snapshot.val()
        setHeading(data.heading)
      })
    }
    readData()
  }, [])
  return (
    <div className="cardContainer">
      <div className="cardBox">
        <div className="head">
          <h1>Vilenium</h1>
        </div>
        <div className="childrenContent">{children}</div>
        {button ? (
          <div className="cardBottom">
            <NavLink to="/imprint">Impressum</NavLink>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardBox
