import React from 'react'
import Dashboard from './Dashboard'

const AdminPage = () => {
  return (
    <div>
        <Dashboard />
    </div>
  )
}

export default AdminPage