import React from 'react'
import CardBox from '../../Component/cardBox'
import Terms from './Terms'

const TermsPage = () => {
  return (
    <div>
        <CardBox button  children={<Terms />} />
    </div>
  )
}

export default TermsPage