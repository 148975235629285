import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCb-6dxQg0P01m3XX-ye9wf7TlV7hHcq3o",
  authDomain: "nasco-687be.firebaseapp.com",
  databaseURL: "https://nasco-687be-default-rtdb.firebaseio.com",
  projectId: "nasco-687be",
  storageBucket: "nasco-687be.appspot.com",
  messagingSenderId: "975035848097",
  appId: "1:975035848097:web:9924894b1e5e7a10d267e0",
  measurementId: "G-YCNZTC77PQ",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
export const database = getDatabase(firebase);
export const auth = getAuth(firebase);
// export const headDb = ref(database, "heading/");
export const storage = getStorage(firebase);
export const dbRef = (path) => {
  return ref(database, `${path}/`);
};
// export default firebase
