import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AdminPage from '../Screens/Dashboard/Index'
import DocumentPage from '../Screens/Documents'
import Download from '../Screens/Download'
import ImPrintPage from '../Screens/Imprint/Index'
import Login from '../Screens/login/Login'
import TermsPage from '../Screens/Terms'

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TermsPage />} />
        <Route path="/documents-pdf" element={<DocumentPage />} />
        <Route path="/imprint" element={<ImPrintPage />} />
        <Route path="/admin-panelSari" element={<AdminPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/download' element={<Download />} />
      </Routes>
    </Router>
  )
}

export default AppRouter
