import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { onValue } from 'firebase/database'
import { dbRef } from '../../config/firebaseConfig'

const fieldsSet = {
  operated: null,
  email: null,
  telephone: null,
  fax: null,
  address: null,
  details: null
}
const ImPrint = () => {
  const [fields, setFields] = useState(fieldsSet)
  useEffect(() => {
    const readData = async () => {
      onValue(dbRef('heading'), (snapshot) => {
        const data = snapshot.val()
        setFields({
          // ...fieldsVal,
          // heading: data.heading,
          operated: data.operated,
          email: data.email,
          telephone: data.telephone,
          fax: data.fax,
          address: data.address,
          details : data.details
        })
      })
    }
    readData()
  }, [])
  return (
    <div className="boxContent">
      <div className="backBtn">
        <NavLink to={-1}>← zurück</NavLink>
      </div>
      <div className="termsHead">
        <h2>Impressum</h2>
      </div>
      <div className="imprintContent">
        <div className="impHead">
          <span>{fields.operated ? fields.operated : ''}</span>
          <span> {fields.operated ? "wird betrieben von" :""}</span>
        </div>
        <div className="paras">
          <textarea
            readOnly
            value={
              fields.address
                ? fields.address
                : ''
            }
          ></textarea>
          <div >
            <p>
              {fields.details}
            </p>
          </div>
          {
            fields?.details && <div className="emailLink">
            <p>Kontakt: </p>
            <div className="contacts">
              <span>E-Mail:</span>{' '}
              <a
                href={`mailto:${
                  fields.email
                    ? fields.email
                    : ''
                }`}
              >
                {' '}
                {fields.email
                  ? fields.email
                  : ''}
              </a>
              <br />
              <span>Telefon: </span>
              <a
                href={`tel:${
                  fields.telephone ? fields.telephone : '+49 (0)69 5555 555'
                }`}
              >
                {fields.telephone ? fields.telephone : '+49 (0)69 5555 555'}
              </a>
              <br />
              <span>Fax: </span>
              <a href={`tel:${fields.fax ? fields.fax : '+49 (0)69 5555 555'}`}>
                {fields.fax ? fields.fax : '+49 (0)69 5555 555'}
              </a>
            </div>
          </div>
          }
          
        </div>
      </div>
    </div>
  )
}

export default ImPrint
