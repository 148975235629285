import { database, storage, dbRef } from "./firebaseConfig";
import { set } from "firebase/database";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";

// export const storageRef = ref(storage , 'documents/')

export const setDbHeadings = async (values) => {
  set(dbRef("heading"), {
    heading: values.heading,
    operated: values.operated,
    email: values.email,
    telephone: values.telephone,
    fax: values.fax,
    address: values.address,
    details : values.details
  })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadFirstDocsAndDownload = async (file, path, date) => {
  const storageRef = ref(storage, `documents/${file.name}`);
  uploadBytes(storageRef, file).then((snapshot) => {
    getDownloadURL(storageRef).then((url) => {
      // console.log(url)
      set(dbRef(path), {
        docUrl: url,
        date:date ? date: new Date(),
      }).then((data)=>{
        // console.log(data)
      })
    });
  });
};

export const updateDate = async (url, path, date) => {
  set(dbRef(path), {
    docUrl: url,
    date: date,
  });
};
