import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// 
const Download = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // 
  useEffect(() => {
    if(!location?.state?.link) navigate('/');
  }, [location?.state?.link])
  return (
    <div className='iframe-wrapper'>
      <iframe src={location?.state?.link} style={{
        width: '100%',
        height: "100%",
        maxHeight: "100%",
        // height: '100vh',
        border: 'none',
        overflow: "auto",
        display: "block",
      }} frameBorder="0"></iframe>
    </div>
  );
};

export default Download;