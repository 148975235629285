import React from 'react'
import CardBox from '../../Component/cardBox'
import Documents from './Documents'

const DocumentPage = () => {
  return (
    <div>
      <CardBox button children={<Documents />} />
    </div>
  )
}

export default DocumentPage
