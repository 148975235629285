import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

let alertText = `Bitte bestätigen Sie, dass Sie den Hinweis vollständig gelesen und seinen Inhalt akzeptiert haben. Andernfalls können wir Ihnen die gewünschte Information nicht zur Verfügung stellen.`

const Terms = () => {
  const [checked, setChecked] = useState(false)
  const navigate = useNavigate()
  const handleChecked = (e) => {
    console.log(e.target.id)
    if (e.target.id === 'no') {
      setChecked(false)
    } else if (e.target.id === 'yes') {
      setChecked(true)
    }
  }

  const handleNavigate = () => {
    if (checked) {
      navigate('/documents-pdf')
    } else {
      alert(alertText)
    }
  }

  return (
    <div className="boxContent">
      <div className="termsHead">
        <h2>Hinweis</h2>
      </div>
      <div className="termsContent">
        <p>
          Die auf den folgenden Internet-Seiten enthaltenen Informationen sind
          ausschließlich für Personen bestimmt und diesen zugänglich, die ihren
          Wohnsitz und Aufenthaltsort nicht in den Vereinigten Staaten von
          Amerika, Australien, Kanada oder Japan haben.
        </p>
        <p>
          Die Wertpapiere, die Gegenstand der folgenden Internet-Seiten sind,
          dürfen in den Vereinigten Staaten von Amerika nur nach vorheriger
          Registrierung oder ohne vorherige Registrierung nur aufgrund einer
          Ausnahmeregelung von dem Registrierungserfordernis nach den
          Vorschriften des U.S. Securities Act von 1933 in der derzeit gültigen
          Fassung verkauft oder zum Kauf angeboten werden. Wir beabsichtigen
          nicht, das Angebot und die Wertpapiere, die Gegenstand der folgenden
          Internet-Seiten sind, vollständig oder teilweise in den Vereinigten
          Staaten von Amerika zu registrieren oder ein öffentliches Angebot in
          den Vereinigten Staaten durchzuführen.
        </p>
        <p>
          Die auf den folgenden Internet-Seiten enthaltenen Informationen sind
          nicht zur Weitergabe in die bzw. innerhalb der Vereinigten Staaten von
          Amerika, Australiens, Kanadas oder Japan bestimmt.
        </p>
        <p>
          Der vorstehende Hinweis dient zur Einhaltung kapitalmarktrechtlicher
          Bestimmungen der Vereinigten Staaten von Amerika, Australiens, Kanadas
          und Japans. Bitte bestätigen Sie, dass Sie ihn vollständig gelesen
          haben und seinen Inhalt akzeptieren.
        </p>
        <h5>
          Ich habe den vorstehenden Hinweis vollständig gelesen und akzeptiere
          seinen Inhalt.
        </h5>
        <div className="radios">
          <label htmlFor="no">
            <input type="radio" onChange={handleChecked} name="terms" id="no" />
            Nein
          </label>
          <label htmlFor="yes" style={{ marginLeft: '15px' }}>
            <input
              type="radio"
              onChange={handleChecked}
              name="terms"
              id="yes"
            />
            Ja
          </label>
        </div>
        <div className="submitBtn">
          <button className="blueBtn" onClick={handleNavigate}>bestätigen</button>
          {/* <button className="blueBtn">Cancel</button> */}
        </div>
      </div>
    </div>
  )
}

export default Terms
